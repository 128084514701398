import fetch from 'isomorphic-fetch';

export type ParamsType = {
    limit?: number;
    sort?: string;
    offset?: number;
    orderBy?: string;
}

export type AggregateParams = {
    as: string;
    foreignField: string;
    localField: string;
    required: boolean;
    functions?: any;
}

class Api {
    private baseUrl = `/v1`;

    public async aggregatePatch<T, R>(collectionName: string, lookupCollectionName: string, params: AggregateParams, data?: any, where?: any, foreignWhere?: any) {
        const res = await fetch(`${this.baseUrl}/aggregate/${collectionName}/${lookupCollectionName}`, {
            method: 'POST',
            body: JSON.stringify({
                method: 'PATCH',
                params,
                data,
                foreignWhere,
                where,
                functions: params.functions,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return await res.json() as { items: Array<T & R> };
    }    

    public async aggregateGet<T, R>(collectionName: string, lookupCollectionName: string, params: AggregateParams, data?: any, foreignData?: any) {
        const res = await fetch(`${this.baseUrl}/aggregate/${collectionName}/${lookupCollectionName}`, {
            method: 'POST',
            body: JSON.stringify({
                method: 'GET',
                params,
                data,
                foreignData,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return await res.json() as { items: Array<T & R> };
    }

    public async get<RE, T extends object = object>(collectionName: string, data: T, params?: ParamsType) {
        const res = await fetch(`${this.baseUrl}/${collectionName}`, {
            method: 'POST',
            body: JSON.stringify({
                method: 'GET',
                params,
                data,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return await res.json() as { items: RE[] };
    }

    public async put<RE, T extends object = object>(collectionName: string, data: T, where?: any, params?: ParamsType) {
        const res = await fetch(`${this.baseUrl}/${collectionName}`, {
            method: 'POST',
            body: JSON.stringify({
                method: 'PUT',
                params,
                where,
                data,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return await res.json() as { items: RE[] };
    }

    public async patch<RE, T extends object = object>(collectionName: string, data: T, where?: any, functions?: any) {
        const res = await fetch(`${this.baseUrl}/${collectionName}`, {
            method: 'POST',
            body: JSON.stringify({
                method: 'PATCH',
                functions,
                where,
                data,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return await res.json() as { items: RE[] };
    }

    public async post<R, T extends Omit<R, 'id'> = Omit<R, 'id'>>(collectionName: string, data: T, params?: ParamsType) {
        const res = await fetch(`${this.baseUrl}/${collectionName}`, {
            method: 'POST',
            body: JSON.stringify({
                method: 'POST',
                params,
                data,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return await res.json() as { items: R[] };
    }
}

export const api = new Api();
