import { useEffect, useState, TouchEventHandler, useCallback, MouseEventHandler, useRef } from 'react';
import qs from 'qs';
import throttle from 'lodash/throttle';
import { COLLECTION_NAME } from './constants';
import { State } from './constants/state';
import { api } from './helpers/request';
import {
  ImageBox,
  WrapBox,
  AppStyles,
  RectBox,
  ControlsBox,
  ButtonBox,
  PinchImgBox,
  ErrorLabel
} from './styles';
import pinch from './pinch.png';

import type { CatFaceState, RectType } from './types';

let touches: { x: number; y: number }[] = [];

type Sizes = {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

type SizeType = { width: number; height: number };

const getRect = (): RectType | null => {
  if (!Array.isArray(touches) || touches.length < 2) {
    return null;
  }

  const sizes = touches.reduce<Partial<Sizes>>((acc, touch) => {
    if (!acc.top || acc.top > touch.y) {
      acc.top = touch.y;
    }

    if (!acc.bottom || acc.bottom < touch.y) {
      acc.bottom = touch.y;
    }

    if (!acc.left || acc.left > touch.x) {
      acc.left = touch.x;
    }

    if (!acc.right || acc.right < touch.x) {
      acc.right = touch.x;
    }

    return acc;
  }, {});

  if (!sizes.left || !sizes.right || !sizes.top || !sizes.bottom) {
    return null
  }

  return {
    x: sizes.left,
    y: sizes.top,
    width: sizes.right - sizes.left,
    height: sizes.bottom - sizes.top
  };
}

const calculateRect = throttle(getRect, 100);

const getX = (x: number, borders: Sizes) => {
  if (x < borders.left) {
    return borders.left;
  }

  if (x > borders.right) {
    return borders.right;
  }

  return x;
}

const getY = (y: number, borders: Sizes) => {
  if (y < borders.top) {
    return borders.top;
  }

  if (y > borders.bottom) {
    return borders.bottom;
  }

  return y;
}

const positiveFilter = (num: number) => num < 0 ? 0 : num;

const getScale = (wrapSize: SizeType, imageSize: SizeType) => {
  const widthScale = imageSize.width / wrapSize.width;
  const heightScale = imageSize.height / wrapSize.height;

  return Math.max(widthScale, heightScale);
}

const getBorders = (wrapSize: SizeType, sceenImageSize: SizeType) => {
  return {
    left: wrapSize.width === sceenImageSize.width ? 0 : (wrapSize.width / 2) - (sceenImageSize.width / 2),
    right: wrapSize.width === sceenImageSize.width ? wrapSize.width - 5 : (wrapSize.width / 2) + (sceenImageSize.width / 2),
    top: wrapSize.height === sceenImageSize.height ? 0 : (wrapSize.height / 2) - (sceenImageSize.height / 2),
    bottom: wrapSize.height === sceenImageSize.height ? wrapSize.height - 5 : (wrapSize.height / 2) + (sceenImageSize.height / 2)
  };
}

const correctRect = (rect: RectType | null, wrapSize: SizeType | null, imageSize: SizeType | null): RectType | null => {
  if (!rect || !wrapSize || !imageSize) {
    return null;
  }

  const scale = getScale(wrapSize, imageSize);
  const sceenImageSize = {
    width: imageSize.width / scale,
    height: imageSize.height / scale,
  };

  const borders: Sizes = getBorders(wrapSize, sceenImageSize);

  const targetX = getX(rect.x, borders);
  const targetY = getY(rect.y, borders);

  const xDiff = positiveFilter(targetX - rect.x);
  const yDiff = positiveFilter(targetY - rect.y);

  const targetWith = getX(rect.x + rect.width, borders) - rect.x - xDiff;
  const targetHeight = getY(rect.y + rect.height, borders) - rect.y - yDiff;

  const targetRect: RectType = {
    x: targetX,
    y: targetY,
    width: positiveFilter(targetWith),
    height: positiveFilter(targetHeight),
  }

  if (targetRect.width === 0 || targetRect.height === 0) {
    return null;
  }

  return targetRect;
}

const getResultRect = (rect: RectType, wrapSize: SizeType, imageSize: SizeType): RectType => {
  const scale = getScale(wrapSize, imageSize);
  const sceenImageSize = {
    width: imageSize.width / scale,
    height: imageSize.height / scale,
  };

  const borders: Sizes = getBorders(wrapSize, sceenImageSize);

  const offsetRect: RectType = {
    x: rect.x - borders.left,
    y: rect.y - borders.top,
    width: rect.width,
    height: rect.height
  };

  return {
    x: offsetRect.x * scale,
    y: offsetRect.y * scale,
    width: offsetRect.width * scale,
    height: offsetRect.height * scale
  }
}

function App() {
  const [image, setImage] = useState<string | null>(null);
  const [rect, setRect] = useState<RectType | null>(null);
  const [isMouseDown, setMouseDown] = useState<boolean>(false);
  const imageWrapRef = useRef<HTMLDivElement | null>(null);
  const [imageSize, setImageSize] = useState<SizeType | null>(null);
  const [isPinchShow, setPinchShow] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const query = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
      });

      if (!query.stateId) {
        return;
      }

      try {
        const response = await api.get<CatFaceState>(COLLECTION_NAME, {
          id: query.stateId
        })

        if (!Array.isArray(response.items) || !response.items[0] || !response.items[0].imageUrl || response.items[0].state !== State.FACE_NOT_FOUND) {
          setError(true);

          return;
        }

        const img = new Image();

        img.onload = function () {
          setImage(img.src);
          setImageSize({
            width: img.width,
            height: img.height
          });
          setIsLoading(false);
        }

        img.src = response.items[0].imageUrl;

      } catch (err) {
        setError(true);

        return;
      }
    })();

    setPinchShow(true);

    timerRef.current = setTimeout(() => {
      setPinchShow(false);
    }, 700);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }
  }, []);

  const handleTouchStart: TouchEventHandler = useCallback((e) => {
    touches = [{
      x: e.changedTouches[0].clientX,
      y: e.changedTouches[0].clientY
    }];
  }, []);

  const handleMouseDown: MouseEventHandler = useCallback((e) => {
    touches = [{
      x: e.clientX,
      y: e.clientY
    }];

    setMouseDown(true);
  }, []);

  const handleTouchMove: TouchEventHandler = useCallback((e) => {
    const currentTouch = e.changedTouches.item(e.changedTouches.length - 1);
    touches[1] = {
      x: currentTouch.clientX,
      y: currentTouch.clientY
    };

    const newRect = calculateRect();

    if (!newRect || rect === newRect) {
      return;
    }

    setRect(correctRect(newRect, imageWrapRef.current?.getBoundingClientRect() || null, imageSize));
  }, [imageWrapRef, imageSize]);

  const handleMouseMove: MouseEventHandler = useCallback((e) => {
    if (!isMouseDown) {
      return;
    }

    touches[1] = {
      x: e.clientX,
      y: e.clientY
    };

    const newRect = calculateRect();

    if (!newRect || rect === newRect) {
      return;
    }

    setRect(correctRect(newRect, imageWrapRef.current?.getBoundingClientRect() || null, imageSize));
  }, [isMouseDown, imageWrapRef, imageSize]);

  const handleMouseUp: MouseEventHandler = useCallback(() => {
    setMouseDown(false);
  }, []);

  const handleSubmit = async () => {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    const wrapSize = imageWrapRef.current?.getBoundingClientRect();

    if (!rect || !wrapSize || !imageSize) {
      return;
    }

    setIsLoading(true);

    try {
      await api.patch<CatFaceState>(COLLECTION_NAME, {
        state: State.DRAW_FACES,
        faces: [getResultRect(rect, wrapSize, imageSize)],
        faceIds: [0],
      }, {
        id: query.stateId,
      });

      window.location.href = "/bot-redirect";
    } catch (err) {
      setError(true);
    }
  }

  const handleBackToBot = () => {
    window.location.href = "/bot-redirect";
  };

  const handleClear = () => {
    setRect(null);
  };

  const handleClickQuestion = useCallback(() => {
    setPinchShow(true);

    timerRef.current = setTimeout(() => {
      setPinchShow(false);
    }, 700);
  }, [timerRef]);

  return (
    <>
      <AppStyles />
      <WrapBox>
        {isError && (
          <div>
            <ErrorLabel>
              Видимо что то пошло не так
            </ErrorLabel>
            <ControlsBox>
              <ButtonBox onClick={handleBackToBot}>
                Вернуться в бот
              </ButtonBox>
            </ControlsBox>
          </div>
        )}
        {!isError && (
          <>
            <PinchImgBox src={pinch} $show={isPinchShow} />
            {image && (
              <ImageBox
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchMove}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                ref={(ref) => imageWrapRef.current = ref}
                $src={image}
              >
                {rect && (
                  <RectBox $width={rect.width} $height={rect.height} $x={rect.x} $y={rect.y} />
                )}
              </ImageBox>
            )}
            <ControlsBox $disabled={isLoading}>
              {rect && (
                <>
                  <ButtonBox onClick={handleSubmit}>
                    Готово
                  </ButtonBox>
                  <ButtonBox $white onClick={handleClear}>
                    Очистить
                  </ButtonBox>
                </>
              )}
              {!rect && (
                <ButtonBox onClick={handleClickQuestion}>
                  Как выделить?
                </ButtonBox>
              )}
            </ControlsBox>
          </>
        )}
      </WrapBox>
    </>
  );
}

export default App;
