import styled, { createGlobalStyle, css } from "styled-components";

export const AppStyles = createGlobalStyle`
    body {
        overflow: hidden;
        height: 100%;
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html, #root {
        height: 100%;
    }
`;

type ImageProps = {
    $src: string;
}

export const ImageBox = styled.div<ImageProps>`
    flex: 1;
    position: relative;

    background-image: url(\"${({ $src }) => $src}\");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
`;

type RectProps = {
    $x: number;
    $y: number;
    $width: number;
    $height: number;
}

export const RectBox = styled.div<RectProps>`
    position: absolute;
    left: ${({ $x }) => $x}px;
    top: ${({ $y }) => $y}px;
    width: ${({ $width }) => $width}px;
    height: ${({ $height }) => $height}px;
    border: 2px solid red;
`;

export const WrapBox = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

type ControlsProps = {
    $disabled?: boolean;
}

export const ControlsBox = styled.div<ControlsProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 12px;

    ${({ $disabled }) => $disabled && css`
        visibility: hidden;
    `}
`;

type ButtonProps = {
    $white?: boolean;
}

export const ButtonBox = styled.span<ButtonProps>`
    flex: 1;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 400px;

    color:  ${({ $white }) => $white ? 'inherit' : 'white'};
    font-size: 18px;
    background-color: ${({ $white }) => $white ? 'rgb(220, 220, 220)' : 'rgb(218, 57, 43)'};
    border-radius: 8px;
    border: 1px solid ${({ $white }) => $white ? 'rgb(220, 220, 220)' : 'rgb(218, 57, 43)'};
    text-decoration: none;
    cursor: pointer;
    transition: all;
    transition-duration: 300ms;

    &:hover {
        color: inherit;
        background-color: white;
        border-color: rgb(39, 36, 67);
    }

    & + & {
        margin-left: 12px;
    }
`;

type PinchProps = {
    $show: boolean;
}

export const PinchImgBox = styled.img<PinchProps>`
    position: absolute;
    width: 300px;
    height: auto;
    top: 40%;
    left: 55%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0;
    transition: all .7s linear;
    visibility: hidden;

    ${({ $show }) => $show && css`
        top: 60%;
        opacity: 1;
        visibility: visible;
    `}
`;

export const ErrorLabel = styled.div`
    font-size: 24px;
    padding: 0 30px;
    text-align: center;
`;
