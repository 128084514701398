export enum State {
    START,
    DETECTING,
    DETECTING_PROCESS,
    DRAW_FACES,
    DRAW_FACES_PROCESS,
    SELECT_FACE,
    SELECT_TEMPLATE,
    DRAW,
    DRAW_PROCESS,
    FACE_NOT_FOUND
}